export type TTimeline = {
    description: string,
    field_tech_id?: string,
    field_tech_name?: string,
    schedule_date?: string,
    schedule_time?: string,
    state_comments?: string,
    state_id: number,
    updated_at: Array<string>,
    previous_state_id?: number,
    download?: boolean,
    schedule_history?: Array<any>
}

export enum TimelineStates {
    CREATED = 1,
    PRE_QUOTE_SURVEY_REQUESTED = 2,
    PRE_QUOTE_SURVEY_COMPLETED = 3,
    PRE_INSTALLATION_SURVEY_REQUESTED = 4,
    PRE_INSTALLATION_SURVEY_COMPLETED = 5,
    INSTALLATION_KICK_OFF_SURVEY_REQUESTED = 6,
    INSTALLATION_KICK_OFF_SURVEY_COMPLETED = 7,
    COMISSIONING_PENDING = 8,
    COMISSIONING_COMPLETE = 9,
    PAUSED = 10,
    CANCELLED = 11,
    PREVENTIVE_MAINTENANCE_REQUESTED = 12,
    PREVENTIVE_MAINTENANCE_COMPLETED = 13,
    EVALUATION_MAINTENANCE_REQUESTED = 14,
    EVALUATION_MAINTENANCE_COMPLETED = 15,
    CORRECTIVE_MAINTENANCE_REQUESTED = 16,
    CORRECTIVE_MAINTENANCE_COMPLETED = 17,
    COMISSIONING_COMPLETE_ASSET = 18,
    PAUSED_ASSET = 19,
    CANCELLED_ASSET= 20,
    CREATED_ASSET = 21
}

export enum TimelineStateActions {
    UPDATE_STATUS = 'UPDATE_STATUS'
}

export const TIMELINE_CONFIGS: { [key: number]: { label: string}} = {
    [TimelineStates.CREATED]: {
        label: "page.field2.0.processesTable.state.CREATED"
    },
    [TimelineStates.PRE_QUOTE_SURVEY_REQUESTED]: {
        label: "page.field2.0.processesTable.state.PRE_QUOTE_SURVEY_REQUESTED"
    },
    [TimelineStates.PRE_QUOTE_SURVEY_COMPLETED]: {
        label: "page.field2.0.processesTable.state.PRE_QUOTE_SURVEY_COMPLETED"
    },
    [TimelineStates.PRE_INSTALLATION_SURVEY_REQUESTED]: {
        label: "page.field2.0.processesTable.state.PRE_INSTALLATION_SURVEY_REQUESTED"
    },
    [TimelineStates.PRE_INSTALLATION_SURVEY_COMPLETED]: {
        label: "page.field2.0.processesTable.state.PRE_INSTALLATION_SURVEY_COMPLETED"
    },
    [TimelineStates.INSTALLATION_KICK_OFF_SURVEY_REQUESTED]: {
        label: "page.field2.0.processesTable.state.INSTALLATION_KICK_OFF_SURVEY_REQUESTED"
    },
    [TimelineStates.INSTALLATION_KICK_OFF_SURVEY_COMPLETED]: {
        label: "page.field2.0.processesTable.state.INSTALLATION_KICK_OFF_SURVEY_COMPLETED"
    },
    [TimelineStates.COMISSIONING_PENDING]: {
        label: "page.field2.0.processesTable.state.COMISSIONING_PENDING"
    },
    [TimelineStates.COMISSIONING_COMPLETE]: {
        label: "page.field2.0.processesTable.state.COMISSIONING_COMPLETE"
    },
    [TimelineStates.PAUSED]: {
        label: "page.field2.0.processesTable.state.PAUSED"
    },
    [TimelineStates.CANCELLED]: {
        label: "page.field2.0.processesTable.state.CANCELLED"
    },
    [TimelineStates.PREVENTIVE_MAINTENANCE_REQUESTED]: {
        label: "page.field2.0.processesTable.state.PREVENTIVE_MAINTENANCE_REQUESTED"
    },
    [TimelineStates.PREVENTIVE_MAINTENANCE_COMPLETED]: {
        label: "page.field2.0.processesTable.state.PREVENTIVE_MAINTENANCE_COMPLETED"
    },
    [TimelineStates.EVALUATION_MAINTENANCE_REQUESTED]: {
        label: "page.field2.0.processesTable.state.EVALUATION_MAINTENANCE_REQUESTED"
    },
    [TimelineStates.EVALUATION_MAINTENANCE_COMPLETED]: {
        label: "page.field2.0.processesTable.state.EVALUATION_MAINTENANCE_COMPLETED"
    },
    [TimelineStates.CORRECTIVE_MAINTENANCE_REQUESTED]: {
        label: "page.field2.0.processesTable.state.CORRECTIVE_MAINTENANCE_REQUESTED"
    },
    [TimelineStates.CORRECTIVE_MAINTENANCE_COMPLETED]: {
        label: "page.field2.0.processesTable.state.CORRECTIVE_MAINTENANCE_COMPLETED"
    },
    [TimelineStates.COMISSIONING_COMPLETE_ASSET]: {
        label: "page.field2.0.processesTable.state.COMISSIONING_COMPLETE"
    },
    [TimelineStates.PAUSED_ASSET]: {
        label: "page.field2.0.processesTable.state.PAUSED"
    },
    [TimelineStates.CANCELLED_ASSET]: {
        label: "page.field2.0.processesTable.state.CANCELLED"
    },
    [TimelineStates.CREATED_ASSET]: {
        label: "page.field2.0.processesTable.state.CREATED"
    }

}