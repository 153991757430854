export const PRODUCT_IDS = {
	SPV: 1,//SOLAR_PHOTOVOLTAIC
	IE: 2,//EFFICIENT_LIGHTING
	MS: 3,//MONITORING_SYSTEM
	INTEGRA: 4,//CORRECTIVE_MAINTENANCE_INSURANCE
	GBHP: 5,//GAS_BOILERS_AND_HEAT_PUMPS
	HVAC: 6,//HEATING_VENTILATION_AND_AIR_CONDITIONING
	CE: 7,//ENERGY_CERTIFICATIONS_AND_AUDITS
	CFP: 8,//POWER_FACTOR_CORRECTION
	SANTS: 9,//INCREASE_IN_VOLTAGE_LEVEL_SERVICE
	MAR: 10,//HIGH_EFFICIENCY_MOTORS
	VEV: 11,//VARIABLE_SPEED_DRIVES
	MPT: 14,//VOLTAGE_TRANSFORMERS_MAINTENANCE
	ME: 16,//ELECTRIC_MOBILITY
	GNS: 17,//NATURAL GAS AND AGGREGATED SERVICES
	CAS: 19,//COMPRESSED_AIR_SYSTEM
	TRE: 20,//ELECTRICAL_INSTALLATIONS_RESPONSIBLE_TECHNICIAN
	BP: 21,//BUILDING_PORTFOLIO
	BS: 22,//BUILDING_SCOPE
	SS: 23,//SOLAR_SUITE
	EO: 24,//ENERGY_OPTIX
	SANTT: 25,//INCREASE_IN_VOLTAGE_LEVEL_TARIFF
	EP: 26,//PROPOSAL ENERGY
	SPVSB: 27,//SOLAR PHOTOVOLTAIC SMALL BUSINESS
	EVCSB: 30,//ELECTRIC VEHICLE CHARGER SMALL BUSINESS
	RR: 32
};

export const PRODUCT_TAG = {
	SPV: "SOLAR_PHOTOVOLTAIC",
	SPV_BATTERY: 'SOLAR_PHOTOVOLTAIC_WITH_BATTERY',
	IE: "EFFICIENT_LIGHTING",
	MS: "MONITORING_SYSTEM",
	INTEGRA: "CORRECTIVE_MAINTENANCE_INSURANCE",
	GBHP: "GAS_BOILERS_AND_HEAT_PUMPS",
	HVAC: "HEATING_VENTILATION_AND_AIR_CONDITIONING",
	CE: "ENERGY_CERTIFICATIONS_AND_AUDITS",
	CFP: "POWER_FACTOR_CORRECTION",
	SANTS: "INCREASE_IN_VOLTAGE_LEVEL_SERVICE",
	MAR: "HIGH_EFFICIENCY_MOTORS",
	VEV: "VARIABLE_SPEED_DRIVES",
	MPT: "VOLTAGE_TRANSFORMERS_MAINTENANCE",
	ME: "ELECTRIC_MOBILITY",
	GNS: 17,//NATURAL GAS AND AGGREGATED SERVICES
	CAS: "COMPRESSED_AIR_SYSTEM",
	TRE: "ELECTRICAL_INSTALLATIONS_RESPONSIBLE_TECHNICIAN",
	BP: "BUILDING_PORTFOLIO",
	BS: "BUILDING_SCOPE",
	SS: "SOLAR_SUITE",
	EO: "ENERGY_OPTIX",
	SANTT: "INCREASE_IN_VOLTAGE_LEVEL_TARIFF",
	EP: 26,//PROPOSAL ENERGY
	SPVSB: "SOLAR_PHOTOVOLTAIC_SMALL_BUSINESS",//SOLAR PHOTOVOLTAIC SMALL BUSINESS
	RR: 'ROOF_REPLACEMENT'
};

//Product BANNER according to image format and id
export const PRODUCTS_IN_PNG_BY_PRODUCTS_IDS = [
	PRODUCT_IDS.SPV,
	PRODUCT_IDS.IE,
	PRODUCT_IDS.IE_BR,
	PRODUCT_IDS.CE,
	PRODUCT_IDS.MPT,
	PRODUCT_IDS.TRE,
	PRODUCT_IDS.GNS,
	PRODUCT_IDS.EP,
	PRODUCT_IDS.IE_BR,
	PRODUCT_IDS.EVC,
	PRODUCT_IDS.EVCSB,
	PRODUCT_IDS.SPVSB,
	PRODUCT_IDS.HP,
	PRODUCT_IDS.INTEGRA,
	PRODUCT_IDS.GBHP,
	PRODUCT_IDS.HVAC,
	PRODUCT_IDS.CFP,
	PRODUCT_IDS.SANTS,
	PRODUCT_IDS.MAR,
	PRODUCT_IDS.CAS,
	PRODUCT_IDS.SANTT,
	PRODUCT_IDS.HVAC_BR,
	PRODUCT_IDS.SPV_SIMPLE,
];

export const productImgName = (tp_id, tp_tag) => {
	return PRODUCTS_IN_PNG_BY_PRODUCTS_IDS.includes(parseInt(tp_id)) ?
		`${tp_tag}.png` : `${tp_tag}.jpeg`;
}
