import axios from "axios";
import jwt_decode from 'jwt-decode';
//Constants
import { CONFIG_HEADERS } from "constants/endpoints";
import { API_ERROR_TAGS, BASENAME_URL } from "constants/settings";
import {
    MSG_SERVER_ERROR,
    OK,
    SESSION_EXPIRED,
    TOAST_CHECK_STATUS_ERROR_ID,
    TOAST_MSG_SERVER_ERROR_ID,
    TOAST_SESSION_EXPIRED_ID,
    UNAUTHORIZED,
    TOAST_MSG_BAD_REQUEST_ID
} from "constants/statusCodeHttp";
// Other
import { history } from 'store';
import { arrayBufferToJSON, isDefined, isExistWord } from "services/util/auxiliaryUtils";
import { REFORMULATED_ERROR_TAGS, TIR_ERRORS } from "constants/processDetails";
import useNotifications from "hooks/notifications";
import { StatusCodes } from "http-status-codes";
import { SentryCaptureException } from "./util/sentry";

const { toastNotify } = useNotifications(); // eslint-disable-line react-hooks/rules-of-hooks


//#region agentkeepalive //TODO: disabled tmp
// const HttpAgentKeepAlive = require('agentkeepalive');
// const HttpsAgentKeepAlive = require('agentkeepalive').HttpsAgent;
// const agentkeepaliveConfig = {
//     httpAgent: new HttpAgentKeepAlive({
//         maxSockets: 512,
//         maxFreeSockets: 100,
//         timeout: 60000, // active socket keepalive for 60 seconds
//         freeSocketTimeout: 30000, // free socket keepalive for 30 def
//     }),
//     httpsAgent: new HttpsAgentKeepAlive({
//         maxSockets: 512,
//         maxFreeSockets: 100,
//         timeout: 60000, // active socket keepalive for 60 seconds
//         freeSocketTimeout: 30000, // free socket keepalive for 30 seconds
//     })
// };
//#endregion agentkeepalive


//* MockAdapter AXIOS INSTANCE *\\
export const axiosMockAdapterInstance = axios.create();


//* AXIOS STATIC INSTANCE *\\

//#region Configure headers
axios.defaults.headers.common['Content-Type'] = CONFIG_HEADERS['Content-Type'];
axios.defaults.headers.common['cancellable'] = CONFIG_HEADERS['cancellable'];
axios.defaults.headers.common['timeout'] = CONFIG_HEADERS['timeout'];
// axios.defaults.httpAgent = agentkeepaliveConfig.httpAgent;
// axios.defaults.httpsAgent = agentkeepaliveConfig.httpsAgent;
//#endregion

//#region Request interceptor
axios.interceptors.request.use((config) => {

    //#region public requests
    let isAuth = (isExistWord(config?.url, 'auth'));
    let isResetPwd = (isExistWord(config?.url, 'credentials-email'));
    if (isAuth) return config;
    if (isResetPwd) return config;
    //#endregion public requests


    try {
        let token = localStorage.getItem('userToken');
        let nowDate = Date.now() / 1000;
        let jwtDecodedToken = jwt_decode(token);
        let tokenExpiryDate = jwtDecodedToken.exp;

        if (nowDate >= tokenExpiryDate) { // If token is valid but already expired
            localStorage.removeItem('userToken');
            localStorage.removeItem("has_asset_management");
            history.replace(BASENAME_URL + 'login');
            toastNotify('warning', SESSION_EXPIRED, TOAST_SESSION_EXPIRED_ID);

            return Promise.reject("Logging out due to expired token");
        }

        config.headers['Authorization'] = "JWT " + localStorage.getItem('userToken');
        return config;
    }
    catch (error) {  // If token is invalid
        localStorage.removeItem('userToken');
        localStorage.removeItem("has_asset_management");
        history.replace(BASENAME_URL + 'login');
        toastNotify('warning', SESSION_EXPIRED, TOAST_SESSION_EXPIRED_ID);

        return Promise.reject("Logging out due to invalid token: ", error);
    }
}, (error) => {
    return Promise.reject(error);
});
//#endregion


//#region Response interceptor
axios.interceptors.response.use(undefined, (data) => {
    // console.log("Error", error.toJSON());
    // console.log("Error", error.response);

    if (!isDefined(data?.response?.status)) return;
    switch (data?.response?.status) {
        case OK:
            break;
        case UNAUTHORIZED: //UNAUTHORIZE > LOGOUT
            localStorage.removeItem('userToken');
            localStorage.removeItem("has_asset_management");
            history.replace(BASENAME_URL + 'login');
            toastNotify('warning', SESSION_EXPIRED, TOAST_SESSION_EXPIRED_ID);
            break;
        case StatusCodes.BAD_GATEWAY:
        case StatusCodes.SERVICE_UNAVAILABLE:
        case StatusCodes.INTERNAL_SERVER_ERROR: {
            //#region monitorization 50X
            let endpoint = data?.config?.url ?? null;
            let payload = data?.response?.config?.data ?? null;
            const message = `API-Warning | status: ${data?.response?.status} | message: ${data?.response?.data?.message ?? data?.response?.data?.data}`;
            SentryCaptureException({
                level: 3,
                message,
                fingerprint: message,
                context: { endpoint },
                extrasContext: {
                    endpoint,
                    payload,
                    response: {
                        ...data?.response?.data,
                    },
                    axiosCode: data?.code ?? null,
                    axiosMessage: data?.message ?? null,
                    method: data?.config?.method ?? null,
                },
                tags: {
                    endpoint,
                    statusCode: data?.response?.status,
                    axiosCode: data?.code ?? null,
                }
            });
            //#endregion
            toastNotify(MSG_SERVER_ERROR, {
                toastId: TOAST_MSG_SERVER_ERROR_ID
            });
            break;
        }
        case StatusCodes.UNPROCESSABLE_ENTITY:
        case StatusCodes.BAD_REQUEST: {
            let endpoint = data?.config?.url ?? null;;
            let payload = data?.response?.config?.data ?? null;
            const message = `API-Error | status: ${data?.response?.status} | message: ${data?.response?.data?.message ?? data?.response?.data?.data}`;
            //#region monitorization status 400
            SentryCaptureException({
                level: 2,
                message,
                fingerprint: message,
                extrasContext: {
                    endpoint,
                    payload,
                    response: {
                        ...data?.response?.data,
                    },
                    axiosCode: data?.code ?? null,
                    axiosMessage: data?.message ?? null,
                    method: data?.config?.method ?? null,
                },
                tags: {
                    endpoint,
                    statusCode: data?.response?.status,
                    axiosCode: data?.code ?? null,
                }
            });
            //#endregion
            let array_buffer_message;
            const isArrayBuffer = data.response.data instanceof ArrayBuffer

            // Needs convertion when response data is an ArrayBuffer
            if (isArrayBuffer) array_buffer_message = arrayBufferToJSON(data).message

            if (![
                REFORMULATED_ERROR_TAGS.DUPLICATE_CONTRACT_ID,
                REFORMULATED_ERROR_TAGS.DUPLICATE_CONTRACT_ID_NEW,
                REFORMULATED_ERROR_TAGS.INVALID_CONTRACT,
                REFORMULATED_ERROR_TAGS.INVALID_CONTRACT_ID_NEW,
                REFORMULATED_ERROR_TAGS.INVALID_CONTRACT_INFORMATION,
                API_ERROR_TAGS.FILES_TOO_LARGE,
                API_ERROR_TAGS.EMAIL_NOT_REGISTERED,
                API_ERROR_TAGS.NO_FILES_AVAILABLE,
                API_ERROR_TAGS.NO_PROPOSAL_FOUND,
                API_ERROR_TAGS.NO_PROCESS_FOUND,
                TIR_ERRORS.MISSING_TARIFF,
                TIR_ERRORS.NOMINAL_POWER_TRESHOLD,
                TIR_ERRORS.CUSTOMER_RISK,
                TIR_ERRORS.GENERAL_ERROR,
                TIR_ERRORS.MISSING_LOCAL_ENERGY_COMMUNITY_OBJ].includes(isArrayBuffer ? array_buffer_message : data?.response?.data?.message))
                toastNotify('error',
                    data.response.data.message,
                    // <IntlMessages id={"error." + error.response.data.message} />,
                    TOAST_MSG_BAD_REQUEST_ID
                );
            break;
        }
        default:
            let isHeadSupportDocs = (data.response.config.method === 'head');//only support-docsD
            if (!isHeadSupportDocs) {
                toastNotify('error', data.response?.data?.message ?? data.response.message, TOAST_CHECK_STATUS_ERROR_ID);

                //#region monitorization status 4xx || 5xx
                let endpoint = data?.config?.url ?? null;
                if ([
                    StatusCodes.NOT_FOUND,
                    StatusCodes.TOO_MANY_REQUESTS,
                    StatusCodes.GATEWAY_TIMEOUT,
                ].includes(data?.response?.status)) {
                    const payload = data?.response?.config?.data ?? null;
                    const titleMessage = (StatusCodes.GATEWAY_TIMEOUT === data?.response?.status)
                        ? 'GATEWAY_TIMEOUT'
                        : data?.response?.data?.message ?? (data?.response?.data?.data ?? data?.response?.data);

                    const message = `API-Warning | status: ${data?.response?.status} | message: ${titleMessage}`;
                    SentryCaptureException({
                        level: 3,
                        message,
                        fingerprint: message,
                        extrasContext: {
                            endpoint,
                            payload,
                            method: data?.config?.method ?? null,
                            response: {
                                ...data?.response?.data,
                            },
                            axiosCode: data?.code ?? null,
                            axiosMessage: data?.message ?? null,
                        },
                        tags: {
                            endpoint,
                            statusCode: data?.response?.status,
                            axiosCode: data?.code ?? null,
                        }
                    });
                }
                //#endregion
            }
            break;
    }
    return data.response;
});
//#endregion


export default axios;